export const LIMIT = {
  HOME_PRODUCTS: 16,
  HOME_BLOGS: 5,
  HOME_COLLECTIONS: 3,
  PRODUCTS_FILTER: 10,
  PRODUCTS_CATEGORY: 16,
  BLOG_LIST: 5,
  BLOG_SIDEBAR: 4,
  BLOGS_CATEGORY: 5,
  BLOG_COMMENTS: 5,
  BLOG_CATEGORY_SIDEBAR: 8,
  CART_PRODUCTS: 16,
};
